<template>
  <div>
    <el-dropdown
        placement="bottom-start"
        trigger="click"
    >
      <el-button>
        {{ value && value.name ? value.name : $t('system.set_status') }}
        <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
            :command="item.id"
            :key="item.id"
            v-for="item in dictionaries && dictionaries[field.dictionary] ? dictionaries[field.dictionary] : []"
        >
          <div @click="chooseItem(item)">{{ item.name }}</div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import abstractForm from '../../mixin/index';

export default {
  mixins: [abstractForm],
  name: 'index',
  data() {
    return {};
  },
  methods: {
    chooseItem(status) {
      this.value = status;
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>

</style>
